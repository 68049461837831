@font-face {
  font-family: 'headingFont';
  src: url('./font/montserrat/Montserrat-Bold.ttf') format('ttf');
}


/* Add these imports to your global CSS file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: 'normalFont', sans-serif;
  /* background-color: #919191; */
}

/* DatePickerStyles.css */
.buynow {
 
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 #FF6600;
  }
  
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}




/* Apply styles to the react-datepicker component */
.react-datepicker__time-container {
  width: 100%;
}

.react-datepicker__time {
  font-size: 15px; /* Customize font size */
}

.react-datepicker__time-box {
  border-radius: 8px; /* Example customization */
}

.react-datepicker__time-list-item {
  
  border-bottom: 1px solid #919191;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 166px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 40px;
  padding: 10px 10px;
  white-space: nowrap;
}

.react-datepicker__time-list-item:hover{
  background-color: #e53935;
}

.bg-dunes {
  background-image: url('./Images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}



.slick-slide img,
.slick-slide:focus img,
.slick-slide:active img,
.slick-slide div:focus img,
.slick-slide div:active img,
.slick-slide div:focus,
.slick-slide div:active {
  outline: none !important;
}




.tag {
  width: 110px;
  height: 34px;
  margin: 10px 0;
  background-color: #ff862f;
  position: relative;
}

.tag::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 0;
  right: 100%;
  /* Position the arrow on the left side of the element */
  border-width: 17px 20px 17px 0;
  /* Adjust border widths to create the arrow pointing left */
  border-style: solid;
  border-color: transparent #ff862f transparent transparent;
  /* Adjust border-color for left-pointing arrow */
}



/* .slick-dots {
  bottom:-50px; 
}
.slick-dots li {
  margin: 0 4px;
}
.slick-dots li button {
  border-radius: 9999px; 
  width: 18px; 
  height: 18px; 
  background-color: #d1d5db; 
}
.slick-dots li.slick-active button {
  background-color: #374151; 
}
.slick-dots li button:hover {
  background-color: #4b5563; 
} */




.movie-slide {
  background: #fff;
  color: #000;
  margin: auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 10px 10px 13px;
  justify-content: center;
  text-align: center;
}

.movie-slide:hover {
  transition: 1s ease-out;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 15px;
}

.container-title {
  display: flex;
  border-bottom: 2px solid;
  width: 380px;
  margin-left: 15px;
  font-size: 25px;
}

.movie-box {
  display: flex;
  overflow-x: scroll;
  padding: 10px 10px;
  scroll-snap-type: x mandatory;
  scroll-padding: 0px;
  justify-content: center;
}

.movie-info {
  margin: 5px 15px 8px 0px;
  scroll-snap-align: start;
  background-color: #fff;
  padding: 10px;

}

.movie-info video {
  background: center center/cover no-repeat #ddd;
  border-radius: 10px 10px 10px 10px;
  height: 350px;
  object-fit: cover;
  object-fit: fill;
}

.movie-name {
  cursor: pointer;
  padding: 0px 5px;
  width: 200px;
  text-align: left;
}

.review {
  font-size: 1.4rem;
  font-weight: 300;
}

.review .fa-solid {
  font-size: 1.8rem;
  color: #e53935;
  margin: 0 10px;
}

.all-btn {
  text-align: center;
  background-color: #FF9800;
  border-radius: 10rem;
  margin: -15px auto 0;
  font-weight: 600;
  width: 220px;
  max-width: 400px;
  padding: 10px;
}

.all-btn:active {
  transform: translateY(2px);
}
@media (max-width: 992px) {
  .movie-box {
    display: flex;
    overflow-x: scroll;
    padding: 10px 10px;
    scroll-snap-type: x mandatory;
    scroll-padding: 0px;
    justify-content: left;
  }
}
@media (max-width: 768px) {

  .movie-box {
    display: flex;
    overflow-x: scroll;
    padding: 10px 10px;
    scroll-snap-type: x mandatory;
    scroll-padding: 0px;
    justify-content: left;
  }
}

@media (max-width: 480px) {
  
.movie-slide {
  background: #fff;
  color: #000;
  margin: auto;
  overflow: hidden;
  padding: 10px 10px 13px;
}
.movie-box {
  display: flex;
  overflow-x: scroll;
  padding: 10px 10px;
  scroll-snap-type: x mandatory;
  scroll-padding: 0px;
  justify-content: left;
}
  .relative {
    font-size: 12px;
  }

 
}





@media only screen and (max-width: 767px) and (min-width: 0) {
  .app {
      clear: both;
      padding: 30px 0 40px;
  }
}

.app {
  background: url(./Images/appbg.jpg) center center no-repeat;
  background-size: inherit;
  padding: 90px 0 40px;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
@media only screen and (max-width: 767px) {
  .app .downtext {
      text-align: center;
      padding: 45px 0 30px;
  }
}

.app .downtext .text-h2, .why .text-h2 {
  color: #ce2127;
  text-transform: uppercase;
}

.app .downtext {
  text-align: right;
  padding: 45px 40px 0 0;
}
audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}
.app .appposition img {
  margin: 0 auto;
}
.app .download {
  padding: 45px 0 0;
  position: relative;
}
.app .download .store {
  color: #ce2127;
  font-size: 14px;
  line-height: 22px;
  background-position: 0 -200px;
  width: 1%;
  display: table-cell;
}
.app .download .store img {
  float: left;
  padding: 5px 15px 0 0;
}
.app .download .store span {
  
  text-transform: uppercase;
  font-size: 15px;
  display: block;
}
.app .download .store {
  color: #ce2127;
  font-size: 14px;
  line-height: 22px;
  background-position: 0 -200px;
  width: 1%;
  display: table-cell;
}

.app .download .store img {
  float: left;
  padding: 5px 15px 0 0;
}
.app .download .store span {
  text-transform: uppercase;
  font-size: 15px;
  display: block;
}






/* Add this to your CSS file */
.markdown-content h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.markdown-content h2 {
  font-size: 2rem;
  font-weight: bold;
}

.markdown-content p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.markdown-content strong {
  font-weight: bold;
}

.markdown-content em {
  font-style: italic;
}

















.word {
  opacity: 0;
  transform: translateY(20px);
}

.fade-in {
  opacity: 1; 
  transform: translateY(0); 
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}




.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 30px;
  line-height: 1;
  /* opacity: .75; */
  color: #FF9800 ;
  z-index: 100;
}

.slick-prev {
  left: 0px;
  z-index: 100;
  top:58%;
  color: #FF9800 ;


}

.slick-next {
  right: 0px;
  top:58%;
  color: #FF9800 ;


}




.hero-section {
  background: linear-gradient(135deg, #FF9800, #ab7423);
  color: white;
}

/* .text-customBlue {
  color: #1D4ED8;
}

.bg-customBlue {
  background-color: #1D4ED8;
} */

.features-list li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.booking-step:hover {
  transform: translateY(-5px);
  transition: transform 0.2s ease-in-out;
}

