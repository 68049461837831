@tailwind base;
@tailwind components;
@tailwind utilities;


/* src/assets/fonts.css */
@font-face {
    font-family: 'HeadingCustomFont';
    src: url('./font/Rubik-Bold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'DetailCustomFont';
    src: url('./font/Rubik-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'headingFont';
    src: url('./font/montserrat/Montserrat-Bold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'normalFont';
    src: url('./font/montserrat/Montserrat-Medium.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  